<template>
  <v-card>
    <v-card-title primary-title v-if="readonly">Terms and Conditions</v-card-title>
    <v-card-title primary-title v-else>User Agreements</v-card-title>
    <v-card-text>
      <v-checkbox
        v-for="(a,i) in agreementItems"
        :key="i"
        v-model="a.checked"
        :label="a.text"
        :disabled="readonly"
      ></v-checkbox>
    </v-card-text>
  </v-card>
</template>

<script>
import { every } from "lodash";

export default {
  name: "UserAgreement",
  data() {
    return {
      agreementItems: [
        {
          text:
            "You agree to occasionally receive an email from us regarding important news/updates/statuses regarding LiveCad",
          checked: false
        },
        {
          text: "You agree not to share your login information with others",
          checked: false
        },
        {
          text:
            "You agree not to share/post screenshots or other information from LiveCad privately or publicly to anyone, including email, text, and online social media sites",
          checked: false
        },
        {
          text:
            "You agree not to re-purpose or re-distribute data from LiveCad for any monetary gains",
          checked: false
        },
        {
          text:
            "You agree not to use information from LiveCad to make contact or pursue services from any potential patient or business",
          checked: false
        },
        {
          text:
            "You agree not to impede or interrupt emergency services that are responding to or at the scene of an incident",
          checked: false
        },
        {
          text:
            "You agree that failure to adhere to the preceding items may result in a temporary or permanent ban from using LiveCad",
          checked: false
        },
        {
          text:
            "You acknowledge that a 911 center's participation in providing data to LiveCad is completely voluntary and may be discontinued (at the 911 center's discretion) at any time, and that such a decision is beyond the control of NIPST/LiveCad",
          checked: false
        },
        {
          text:
            "We agree to keep your personal information private and that we will never give or sell your personal information or usage information to anyone",
          checked: false
        }
      ]
    };
  },
  props: ["readonly"],
  computed: {
    agreementValid() {
      return every(this.agreementItems, { checked: true });
    }
  },
  created() {
    if (this.readonly && this.readonly === true) {
      for (var x = 0; x < this.agreementItems.length; x++) {
        this.agreementItems[x].checked = true;
      }
    }
  },
  watch: {
    agreementItems: {
      handler: function() {
        this.$emit("update:agreement", this.agreementValid);
      },
      deep: true
    }
  }
};
</script>