<template>
  <UserAgreement :readonly="readonly" />
</template>

<script>
import UserAgreement from "./UserAgreement";
export default {
  data() {
    return { readonly: true };
  },
  components: { UserAgreement }
};
</script>

<style>
</style>